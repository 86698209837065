import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from '../theme/theme.custom';
import RaisedButton from 'material-ui/RaisedButton';
import SignupForm from '../SignupForm/SignupForm';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import './App.css';
export default class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false,
      isVan: false,
      showWarning: false
    };
  }
  componentWillMount() {
    console.log(window.location.search);
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('408638906703045');
    ReactPixel.default.pageView();
    ReactGA.initialize('UA-50973971-5');
    ReactGA.pageview(window.location.pathname);
    const params = queryString.parse(window.location.search);
    if (params && params.vehicle === 'van') {
      this.setState({isVan: true, showWarning: false});
    } else if (params && params.d === '1') {
      this.setState({showWarning: false});
    }
  }
  signupCompleted = (vehicle) => {
    this.setState({isCompleted: true});
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://conv.indeed.com/pagead/conversion.js');
    document.head.appendChild(script);
    ReactGA.event({
      category: 'Vehicle',
      action: 'FormSubmitted',
      label: vehicle
    	});
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('408638906703045');
    ReactPixel.default.track('SubmitApplication');
  }
  toggleVan = () => {
    this.setState({isVan: false});
  }
  render() {
    const darkFacebook = require('../images/darkFacebook.png');
    const darkInstagram = require('../images/darkInstagram.png');
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
      <div className="App">
      <a name="SIGNUP" href="#SIGNUP" className="top">&nbsp;</a>
      <Helmet title="Sherpa"/>
      <div className="header">
      {this.state.showWarning && <div style={{background: '#FF9900', maxWidth: '650px', borderRadius: '2px', fontSize: '12px', paddingLeft: '13px', paddingRight: '13px', paddingTop: '8px', paddingBottom: '8px', margin: '0 auto', position: 'relative', top: '-15px'}}>Unfortunately, we’ve exceeded our onboarding capacity in all cities (excluding Sydney, Perth & Sunshine Coast) for the remainder of the year. Continue signing up to get approved early next year. We will recommence approving accounts from 4 Jan, 2020.</div>}
      <div style={{width: '100%', height: '10px'}}/>
      <div className="headerContents">
        <a href="https://www.sherpa.net.au"><div className="lamma"></div></a>
        <div className="cloud"/>
        <div>
           <a href="https://www.instagram.com/sherpa_delivery"><div className="instagram"></div></a>
           <a href="https://www.facebook.com/getasherpa"><div className="facebook"></div></a>
           <div className="everyonTitle" />
        </div>
        <div className="clear"/>
        {!this.state.isCompleted && <div className="headerIntro">
          <h5>WE'RE ALWAYS HIRING...</h5>
          {this.state.isVan && <h1 className="normal">We Need You & Your <b>Van-Do</b> Attitude!</h1>}
          {!this.state.isVan && <h1>Do You Have a Motorbike, Car or Van?</h1>}
        </div>}
        {this.state.isVan && <div className="van"/>}
        <div className="biggerSherpaMan"/>
        {!this.state.isCompleted && <SignupForm isVan={this.state.isVan} toggleVan={this.toggleVan} signupCompleted={this.signupCompleted}/>}
        {this.state.isCompleted && <div className="completed">
        <h5>THANK YOU FOR SIGNING UP</h5>
        <h1>We've Sent You an Email with Steps to Complete Your Registration!</h1>
        </div>}
        </div>
      </div>
      <div className="headerBottom">
        <a href="#MORE"><div className="bottomArrow"></div></a>
      </div>
      <div className="sherpaMan"/>
      <div className="feature">
      <a name="MORE" href="#MORE">&nbsp;</a>
      <div className="wrap">
        <h1 className="smaller">Join Our Driver Community Delivering Around Your Local Area!</h1>
        <p className="signupDesc">Alongside the 1,000+ Australian retailers we’re already a proud delivery partner to, we are continually working each day to on-board new business customers to keep the jobs flowing through for you. We think you’ll love the autonomy of working for yourself as a Sherpa driver.</p>
      </div>
      <div className="feature">
      <div className="wrap">
      	<div className="float">
      		<div className="flexible" />
            <h4>Flexibility</h4>
      		<div className="featureDesc">Simply jump onto the app when, where, and as often as you like to view any open jobs near you.</div>
      	</div>
          <div className="float">
            <div className="dollar" />
            <h4>Maximise Your Earnings</h4>
      		<div className="featureDesc">You choose which jobs you accept (avg. delivery price $15) and how many you do</div>
      	</div>
          <div className="float">
            <div className="smile" />
            <h4>Deliver Happiness</h4>
      		<div className="featureDesc">Work closely with our awesome clients to make them and their customers happy!</div>
      	</div>
      	</div>
      	</div>
      </div>
      <div className="clear"/>
      <div className="map">
      <div className="wrap">
      <div className="mapTitle">Operating in 16 Major Cities and Growing… 7am-9pm, 7 Days</div>
      <div className="australia" />
      <div className="mapContents">
      <div className="state">
      NEW SOUTH WALES
       <ul>
        <li>Sydney</li>
        <li>Newcastle</li>
        <li>Wollongong</li>
        <li>Albury-Wodonga</li>
        <li>Gosford</li>
        </ul>
      </div>
      <div className="state">
      QUEENSLAND
       <ul>
      <li>Brisbane</li>
      <li>Cairns</li>
      <li>Gold Coast</li>
      <li>Sunshine Coast</li>
        </ul>
      </div>
      <div className="state">
      VICTORIA
       <ul>
      <li>Melbourne</li>
      <li>Geelong</li>
      <li>Albury-Wodonga</li>
        </ul>
      </div>
      <div className="clear"/>
      <div className="state">
      ACT
       <ul>
        <li>Canberra</li>
       </ul>
      </div>
      <div className="state">
      SOUTH AUSTRALIA
      <ul>
        <li>Adelaide</li>
       </ul>
      </div>
      <div className="state">
      WESTERN AUSTRALIA
      <ul>
      <li>Perth</li>
      </ul>
      </div>
      <div className="clear"/>
      <div className="state">
      TASMANIA
      <ul>
      <li>Launceston</li>
      <li>Hobart</li>
      </ul>
      </div>
      </div>
      </div>
    </div>
      <div className="requirement">
      <div className="wrap">
        <h1 className="smaller">A Couple of Must-Haves for Driving With Us</h1>
        <div className="requiredItem">
        <div className="license" />
        <div>A valid<br/>driver's licence</div>
        </div>
        <div className="requiredItem">
        <div className="vehicle" />
        <div>A vehicle in good condition<br/>with current insurance</div>
        </div>
        <div className="requiredItem">
        <div className="over18" />
        <div>Must be over 18<br/>years of age</div>
        </div>
        <div className="requiredItem">
        <div className="phone" />
        <div>Own a smartphone -<br/>iPhone or Android 4.0</div>
        </div>
        <div className="requiredItem">
        <div className="abn" />
        <div>Must register for an<br/>ABN Number</div>
        </div>
        <div className="requiredItem">
        <div className="communication" />
        <div>Good communication with ability<br/>to speak fluent English</div>
        </div>
        <div className="requiredItem">
        <div className="location" />
        <div>Live close to the center of operating<br/>cities or be willing to travel in</div>
        </div>
        <div className="requiredItem">
        <div className="friendly" />
        <div>Clean, polite and friendly<br/>with a positive attitude</div>
        </div>
        </div>
        </div>
        <div className="feature dealGroup">
           <div className="wrap">
            <h1 className="smaller">We Deliver for a Variety of Business Industries...</h1>
            <div className="dealItem">
            <div className="deal_flowers" />
            <div className="dealName">Flowers & Gifts</div>
            </div>
            <div className="dealItem">
            <div className="deal_cakes" />
            <div className="dealName">Catering, Cakes & Bakeries</div>
            </div>
            <div className="dealItem">
            <div className="deal_pr" />
            <div className="dealName">PR & E-commerce</div>
            </div>
            <div className="dealItem">
            <div className="deal_docs" />
            <div className="dealName">Document & Stationery</div>
            </div>
            <div className="dealItem">
            <div className="deal_alcohol" />
            <div className="dealName">Alcohol</div>
            </div>
         </div>
        </div>
        <div className="feature processTitle">
        <div className="wrap">
         <h1 className="smaller">Let's Get You Signed Up and on the Road!</h1>
         <p>The easiest way to sign up and register your driver profile is via the Sherpa Driver App (you can do it via the website too, if you prefer). Either way, have the following handy to become an approved Sherpa Driver in no time.</p>
        </div>
       </div>
        <div className="process">
        <div className="wrap">
          <div className="half">
              <div className="profile" />
              <h4>A Professional Profile Picture</h4>
              <div className="processDesc">This will be used as an indentifier of you for our users, so must be professional.</div>
          </div>
          <div className="half">
          <div className="bubble_license" />
          <h4>A Valid Driver's Licence</h4>
          <div className="processDesc">As you will be a delivery DRIVER, we need proof that you are legally allowed to drive.</div>
          </div>
          <div className="half">
            <div className="bubble_abn" />
            <h4>An ABN (Australian Business Number)</h4>
            <div className="processDesc">It is a legal requirement that you obtain an ABN to receive payments from us.</div>
          </div>
          <div className="half">
            <div className="money" />
            <h4>Bank Account Details</h4>
            <div className="processDesc">The best part... Getting paid! We need to know where to send the money.</div>
          </div>
          <div className="half">
          <div className="background" />
          <h4>Complete a Background Check</h4>
          <div className="processDesc">Provide a valid police check, and show you have the rights to work in Australia.</div>
          </div>
          <div className="half">
          <div className="insurance" />
          <h4>Proof of Vehicle Insurance</h4>
          <div className="processDesc">We accept a minimum of Third Party Property, or Comprehensive vehicle policies.</div>
        </div>
      </div>
      </div>
      <div className="clear"/>
      <div className="faq">
      <a href="https://help.audriver.sherpadelivery.com/hc/en-us/articles/4408814060557-How-do-I-apply-to-become-a-Sherpa-Driver-">Tell me more about the registration process</a>
      </div>
      <div className="signup">
        <a href="#SIGNUP"><RaisedButton label="Sign Up Now" backgroundColor={'#c4105a'} className="signupButton" style={{width: '300px', lineHeight: '50px', backgroundColor: '#c4105a'}} labelColor={'#ffffff'}/></a>
      </div>
      <div className="footer">
      <div className="footerContents">
       <div className="homepage"><a href="http://www.sherpa.net.au">Sherpa</a> is a technology platform connecting independent courier professionals with businesses.</div>
       <div className="apps"><a href="https://itunes.apple.com/au/app/sherpa-driver/id1040401573?mt=8"><div className="appLogo itunes" /></a><a href="https://play.google.com/store/apps/details?id=com.sherpa.courier.prod"><div className="appLogo googleplay" /></a></div>
       <div className="social"><a href="https://www.facebook.com/getasherpa"><img className="socialFB" alt="" src={darkFacebook} /></a><a href="https://www.instagram.com/sherpa_delivery/"><img alt="" className="socialLogo" src={darkInstagram} /></a></div>
      </div>
     </div>
    </div>
</MuiThemeProvider>
    );
  }
}
