import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import ApiClient from '../helpers/ApiClient';
import MenuItem from 'material-ui/MenuItem';
import IconMenu from 'material-ui/IconMenu';
import './SignupForm.css';

const MOTORBIKE = 'Motorbike';
const CAR = 'Car';
const WAGON = 'Wagon/Ute';
const VAN = 'Van/Truck';
const VEHICLETYPE = 'Vehicle Type';
class SignupForm extends Component {
  static propTypes = {
    signupCompleted: PropTypes.func,
    toggleVan: PropTypes.func,
    isVan: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    this.state = {
      vehicle: VEHICLETYPE,
      error: null,
    };
  }
  componentWillMount = () => {
    const timer = setInterval(() => {
     clearInterval(this.state.timer);
     this.loadGoogleLibrary();
    }, 2000);
    this.setState({timer: timer});
  }
  loadGoogleLibrary = () => {
    const autocomplete = new google.maps.places.Autocomplete( // eslint-disable-line no-undef, new-cap
    /** @type {!HTMLInputElement} */
      (this.refs.addressField), {
        fields: ['place_id', 'name', 'types'],
        types: ['geocode'],
        componentRestrictions: {
          country: 'AU'
        }
      });
    this.setState({
      autocomplete: autocomplete,
      addressError: null,
    });
  }
  vehicleTypePressed = (index, vehicle) => {
    this.setState({vehicle: vehicle, vehicleId: index});
    console.log(vehicle);
  }
  close = () => {
    this.setState({error: null});
  }

  signup = () => {
    if (this.refs.firstNameField.value === '') {
      this.setState({error: 'First name is missing'});
      return;
    }
    if (this.refs.lastNameField.value === '') {
      this.setState({error: 'Last name is missing'});
      return;
    }
    if (this.refs.emailField.value === '') {
      this.setState({error: 'Email address is missing'});
      return;
    }
    if (this.refs.mobileField.value === '') {
      this.setState({error: 'Mobile number is missing'});
      return;
    }
    if (this.refs.addressField.value === '') {
      this.setState({error: 'Address is missing'});
      return;
    }
    if (!this.props.isVan && !this.state.vehicleId) {
      this.setState({error: 'Please select a vehicle type'});
      return;
    }
    if (!this.refs.community.checked) {
      this.setState({error: 'Please read & accept community guidelines'});
      return;
    }

    new ApiClient().post('/couriers', {
      first_name: this.refs.firstNameField.value,
      last_name: this.refs.lastNameField.value,
      email: this.refs.emailField.value,
      mobile_phone: this.refs.mobileField.value,
      vehicle_id: this.props.isVan ? 4 : this.state.vehicleId,
      formatted_address: this.refs.addressField.value,
      accept_community_guidelines: true
    	}).then(
      (data) => {
        if (data !== null && data.error) {
          this.setState({error: data.error});
        } else if (data !== null) {
          this.props.signupCompleted(this.props.isVan ? VAN : this.state.vehicle);
        }
      },

      (errors) => {
        if (errors === undefined || errors.errors === undefined || errors.errors === null) {
          return
        }
        if (errors.errors.first_name) {
          this.setState({error: errors.errors.first_name[0]});
        } else if (errors.errors.last_name) {
          this.setState({error: errors.errors.last_name[0]});
        } else if (errors.errors.email) {
          this.setState({error: `Email ${errors.errors.email[0]}`});
        } else if (errors.errors.mobile_phone) {
          this.setState({error: errors.errors.mobile_phone[0]});
        } else if (errors.errors.latitude) {
          this.setState({error: 'The address you entered does not look valid'});
        }
      }).catch((err)=> {
        console.log(err);
      });
  }
  render() {
    return (
      <div className="signupform">
        {this.state.error === null && <div className="hide_error_box"><span>{this.state.error}</span><div className="close" onClick={this.close.bind(this)}/></div>}
        {this.state.error !== null && <div className="show_error_box"><span>{this.state.error}</span><div className="close" onClick={this.close.bind(this)}/></div>}
        <div className="first_name">
          <input ref="firstNameField" className="field" placeholder="First Name"/>
          <span className="requiredMark">*</span>
        </div>
        <div className="last_name">
          <input ref="lastNameField" className="field" placeholder="Last Name"/>
          <span className="requiredMark">*</span>
        </div>
        <div className="email_box">
          <input ref="emailField" className="field" placeholder="Email Address"/>
          <span className="requiredMark">*</span>
        </div>
        <div className="input_box">
          <input ref="mobileField" className="field" placeholder="Mobile Number"/>
          <span className="requiredMark">*</span>
        </div>
        <div className="input_box"><input ref="addressField" className="field" placeholder="Home Address"/><span className="requiredMark">*</span></div>
        <div className="input_box">
        <input type="button" className="vehicleField" placeholder="Vehicle Type" />
        <span className="requiredMark">*</span>
        <div className="dropdown"/>
        <IconMenu style={{display: 'block'}} menuStyle={{width: 120}} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} iconButtonElement={
          <div>
            {this.state.vehicle === VEHICLETYPE && <div className="vehicleTypeHolder">{this.state.vehicle}</div>}
            {this.state.vehicle !== VEHICLETYPE && <div className="vehicleType ">{this.state.vehicle}</div>}
          </div>
        }>
          <MenuItem onClick={this.vehicleTypePressed.bind(this, 2, MOTORBIKE)}>
            <div>
              {MOTORBIKE}
            </div>
          </MenuItem>
          <MenuItem onClick={this.vehicleTypePressed.bind(this, 1, CAR)}>
            <div>
              {CAR}
            </div>
          </MenuItem>
          <MenuItem onClick={this.vehicleTypePressed.bind(this, 5, WAGON)}>
            <div>
              {WAGON}
            </div>
          </MenuItem>
          <MenuItem onClick={this.vehicleTypePressed.bind(this, 4, VAN)}>
            <div>
              {VAN}
            </div>
          </MenuItem>
        </IconMenu>
        {this.props.isVan && (
          <div className="vanLabel">
            <span className="leftVan">I Drive a Van/Wagon</span>
            <a href="/" className="rightVan" onClick={this.props.toggleVan.bind(this)}>I Don't Drive a Van</a>
            <div className="clear"/>
          </div>
        )}
      </div>
      <div className="description">
        <label className="container">&nbsp;&nbsp;&nbsp;&nbsp;I've read and accept the <a href="https://sherpa.net.au/community-guidelines">community guidelines</a>.
        <input type="checkbox" ref="community"/>
        <span className="checkmark"/>
        </label>
      </div>
      <div className="input_box">
        <RaisedButton
          onClick={this.signup}
          label="Sign Up to be a Sherpa Driver Now"
          backgroundColor={'#c4105a'}
          className="submitButton"
          style={{ marginTop: '8px', lineHeight: '50px', backgroundColor: '#c4105a'}}
          labelStyle={{textTransform: 'none'}}
          labelColor={'#ffffff'}
        />
      </div>
    </div>
    );
  }
}

export default SignupForm;
